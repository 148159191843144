/**
 * Contact Form 7 Helpers
 *
 * Useful links:
 *    * https://contactform7.com/dom-events/
 */
function initContactForm7Helper(){
    // Contact us: add GTM event tracking for form submissions, no matter whether the email sends or not. Ignore spam submissions.
    ['wpcf7mailsent', 'wpcf7mailfailed'].forEach(function(wpcf7Event) {
        window.document.addEventListener(wpcf7Event, function(event){
            var detail       = event.detail || {},
                dataLayer    = window.dataLayer = window.dataLayer || [];

            if (detail.contactFormId === 141){
                dataLayer.push({"event": "submit-contact-us-form"});
            }
        });
    });
}
