function initAwards() {
    let awardsBodyEl = document.querySelector(".awards-body"),
        awardsTitleEl = document.querySelector(".awards-title"),
        images = document.querySelectorAll(".awards-section__award img");

    for (let i = 0; i < images.length; i++) {
        images[i].addEventListener("click", function () {
            let $this = images[i],
                awardsBody = $this.dataset.body,
                awardsTitle = $this.dataset.title,
                awardsYear = $this.dataset.year,
                awardsPlacing = $this.dataset.placing;

            let bodyText = awardsBody;

            if (awardsYear != "" || awardsPlacing != "") {
                bodyText += " -";
            }

            if (awardsYear != "") {
                bodyText += " " + awardsYear;
            }

            if (awardsPlacing != "") {
                bodyText += " " + awardsPlacing;
            }

            awardsBodyEl.innerHTML = bodyText;
            awardsTitleEl.innerHTML = awardsTitle;
        });
    }
}
