function initMenu() {
    let menuButton = document.querySelector("header .menu-icon");
    menuButton.addEventListener("click", function () {
        document.querySelector("body").classList.toggle("menu-open");
    });

    let closeMenuButton = document.querySelector(".right-off-canvas-menu .close-icon");
    closeMenuButton.addEventListener("click", function () {
        document.querySelector("body").classList.toggle("menu-open");
    });

    let footerMenuButtons = document.querySelectorAll(".footer__menu .secondary, .footer__menu svg");
    for (let i = 0; i < footerMenuButtons.length; i++) {
        footerMenuButtons[i].addEventListener("click", function () {
            footerMenuButtons[i].closest(".footer__menu").classList.toggle("open");
        });
    }
}
