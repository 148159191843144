function initLocalAnalytics() {
    (window._gaq || []).push(function () {
        // Use URI.js to parse the parameters - https://github.com/medialize/URI.js
        var parameters = URI.parseQuery(_gat._getTrackerByName()._getLinkerUrl("")),
            utmz = parameters["__utmz"];
        if (utmz) {
            /*
             * Description of '_utmz' cookie from: http://services.google.com/analytics/breeze/en/ga_cookies/index.html
             * The values in brackets refer to the tracking parameters that can be used to set the values.
             *
             * First number:			domain hash
             * Second number:			timestamp
             * Third number:			session number
             * Fourth number:			campaign number
             * utmcsr (utm_source):		source
             * utmccn (utm_campaign):	campaign
             * utmcmd (utm_medium):		medium
             * utmctr (utm_term):		keyword
             * utmcct (utm_content):	ad content
             *
             * Example value: 1.1295432056.72.5.utmcsr=www.gravytrain.co.uk|utmccn=(referral)|utmcmd=referral|utmcct=/blog/
             */

            // Split the value around dots limiting it to 5 parts as dots can be valid in the 'utm...' values
            var tokens = utmz.split(".", 5),
                _utmz = {},
                textData;
            if (tokens.length === 5) {
                // Parse the numbers from the first 4 parts
                _utmz.domainHash = parseInt(tokens[0]);
                _utmz.timestamp = parseInt(tokens[1]);
                _utmz.sessionNumber = parseInt(tokens[2]);
                _utmz.campaignNumber = parseInt(tokens[3]);

                // Split the last token around the pipe character & loop through the parts
                var utmTokens = tokens[4].split("|");
                for (var count = 0; count < utmTokens.length; count++) {
                    // Get the parts of the value
                    var utmToken = utmTokens[count],
                        matches = /(utm\w+)=(.+)/.exec(utmToken),
                        name = matches[1],
                        value = matches[2];

                    // Set the appropriate field for the token
                    if (name == "utmcsr") {
                        _utmz.source = value;
                    } else if (name == "utmccn") {
                        _utmz.campaign = value;
                    } else if (name == "utmcmd") {
                        _utmz.medium = value;
                    } else if (name == "utmctr") {
                        _utmz.keyword = value;
                    } else if (name == "utmcct") {
                        _utmz.adContent = value;
                    } else if (name == "utmgclid") {
                        _utmz.gclid = value;
                    }
                }
            }

            // Build text data for contact form 7 fields
            textData = "UTM campaign: " + (_utmz.campaign || "n/a") + "\n";
            textData += "UTM medium: " + (_utmz.medium || "n/a") + "\n";
            textData += "UTM source: " + (_utmz.source || "n/a") + "\n";
            textData += "UTM keyword: " + (_utmz.keyword || "n/a") + "\n";
            textData += "UTM ad content: " + (_utmz.adContent || "n/a") + "\n";
            textData += "UTM gclid: " + (_utmz.gclid || "n/a") + "\n";

            // Populate any fields
            //$(".hidden-utm input").val(textData);
            document.querySelector(".hidden-utm input").value = textData;
        }
    });
}
