(function (window) {
    "use strict";

    var SuccessStoriesFilters = {
        config: {
            activeClass: "service--active",
        },
        init: function () {
            var filters = document.querySelector(".filters");
            if (filters) {
                this.setupClicks(filters);
            }
        },
        setupClicks: function (filters) {
            var self = this,
                config = self.config,
                showAll = filters.querySelector(".show-all"),
                expand = filters.querySelector(".filters-expand"),
                list = filters.querySelector(".filters-list"),
                services = filters.querySelectorAll(".service--single");

            this.activate(showAll);

            expand.addEventListener("click", function () {
                expand.classList.toggle("active");
                list.classList.toggle("active");
            });

            for (let i = 0; i < services.length; i++) {
                services[i].addEventListener("click", function () {
                    if (services[i].classList.contains(config.activeClass)) {
                        self.deactivate(services[i]);

                        var activeServices = document.querySelectorAll(".service--single.service--active");

                        if (activeServices.length === 0) {
                            self.activate(showAll);
                        }
                    } else {
                        self.activate(services[i]);
                        self.deactivate(showAll);
                    }

                    self.filterCards();
                });
            }

            if (showAll) {
                showAll.addEventListener("click", function () {
                    if (!showAll.classList.contains(config.activeClass)) {
                        self.activate(showAll);

                        var services = document.querySelectorAll(".service--single");

                        for (let i = 0; i < services.length; i++) {
                            self.deactivate(services[i]);
                        }
                    }

                    self.filterCards();
                });
            }
        },
        deactivate: function (service) {
            service.classList.remove(this.config.activeClass);
            service.querySelector(".checkbox--empty").style.display = "block";
            service.querySelector(".checkbox--checked").style.display = "none";
        },
        activate: function (service) {
            if (service) {
                service.classList.add(this.config.activeClass);
                service.querySelector(".checkbox--empty").style.display = "none";
                service.querySelector(".checkbox--checked").style.display = "block";
            }
        },
        filterCards: function () {
            var config = this.config,
                filters = document.querySelector(".filters"),
                showAll = filters.querySelector(".show-all"),
                cards = document.querySelectorAll(".success-stories-section__card__container"),
                activeServices = document.querySelectorAll(".service--single.service--active");

            if (showAll.classList.contains(config.activeClass)) {
                for (let i = 0; i < cards.length; i++) {
                    cards[i].style.display = "block";
                }
            } else {
                for (let i = 0; i < cards.length; i++) {
                    cards[i].style.display = "none";
                }

                for (let i = 0; i < activeServices.length; i++) {
                    var serviceName = activeServices[i].dataset.service;
                    var activeCards = document.querySelectorAll(".service--" + serviceName);

                    for (let j = 0; j < activeCards.length; j++) {
                        activeCards[j].style.display = "block";
                    }
                }
            }
        },
    };

    window.SuccessStoriesFilters = SuccessStoriesFilters;
})(window);
