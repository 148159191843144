function initContactForm() {
    var scrollButtons = document.querySelectorAll("a.scroll-to-contact");

    for (let i = 0; i < scrollButtons.length; i++) {
        scrollButtons[i].addEventListener("click", function (e) {
            e.preventDefault();

            var offsetTop = document.querySelector("#contact-section").offsetTop;

            scroll({
                top: offsetTop,
                behavior: "smooth",
            });
        });
    }
}
